// TempoSlider.tsx
import React, { useState, useRef, useEffect } from 'react';
import './TempoSlider.css';

interface TempoSliderProps {
    tempo?: number;
    onTempoChange: (tempo: number) => void;
}

const TempoSlider: React.FC<TempoSliderProps> = ({ tempo, onTempoChange }) => {
    const [isDragging, setIsDragging] = useState(false);
    const [currentTempo, setCurrentTempo] = useState(tempo || 100);
    const sliderRef = useRef<HTMLDivElement | null>(null);
    const currentTempoRef = useRef(currentTempo);

    useEffect(() => {
        if (!isDragging && tempo !== undefined && tempo !== null) {
            const smoothingFactor = 0.2; // Adjust between 0 (no change) and 1 (immediate change)
            const intervalId = setInterval(() => {
                const difference = tempo - currentTempoRef.current;
                if (Math.abs(difference) < 0.1) {
                    setCurrentTempo(tempo);
                    currentTempoRef.current = tempo;
                    clearInterval(intervalId);
                } else {
                    const newTempo = currentTempoRef.current + difference * smoothingFactor;
                    setCurrentTempo(newTempo);
                    currentTempoRef.current = newTempo;
                }
            }, 50); // Update every 50ms
            return () => clearInterval(intervalId);
        }
    }, [tempo, isDragging]);

    const handleMouseDown = (e: React.MouseEvent) => {
        setIsDragging(true);
        updateTempoFromMousePosition(e);
        e.preventDefault();
    };

    const handleMouseMove = (e: React.MouseEvent) => {
        if (isDragging) {
            updateTempoFromMousePosition(e);
            e.preventDefault();
        }
    };

    const handleMouseUp = () => {
        if (isDragging) {
            setIsDragging(false);
            currentTempoRef.current && onTempoChange(currentTempoRef.current);
        }
    };

    const handleTouchStart = (e: React.TouchEvent) => {
        setIsDragging(true);
        updateTempoFromTouchPosition(e);
        e.preventDefault();
    };

    const handleTouchMove = (e: React.TouchEvent) => {
        if (isDragging) {
            updateTempoFromTouchPosition(e);
            e.preventDefault();
        }
    };

    const handleTouchEnd = () => {
        if (isDragging) {
            setIsDragging(false);
            currentTempoRef.current && onTempoChange(currentTempoRef.current);
        }
    };

    const updateTempoFromMousePosition = (e: React.MouseEvent) => {
        if (sliderRef.current) {
            const rect = sliderRef.current.getBoundingClientRect();
            const height = rect.bottom - rect.top;
            const y = e.clientY - rect.top;
            const percentage = 1 - y / height;
            const newTempo = 21 + percentage * (200 - 21);
            const clampedTempo = Math.max(21, Math.min(200, newTempo));
            setCurrentTempo(clampedTempo);
            currentTempoRef.current = clampedTempo;
            onTempoChange(clampedTempo);
        }
    };

    const updateTempoFromTouchPosition = (e: React.TouchEvent) => {
        if (sliderRef.current) {
            const rect = sliderRef.current.getBoundingClientRect();
            const height = rect.bottom - rect.top;
            const y = e.touches[0].clientY - rect.top;
            const percentage = 1 - y / height;
            const newTempo = 21 + percentage * (200 - 21);
            const clampedTempo = Math.max(21, Math.min(200, newTempo));
            setCurrentTempo(clampedTempo);
            currentTempoRef.current = clampedTempo;
            onTempoChange(clampedTempo);
        }
    };

    const fillHeight = ((currentTempo || 0) - 21) / (200 - 21) * 100;

    return (
        <div className="tempo-slider-container">
            <div
                className="tempo-slider"
                ref={sliderRef}
                onMouseDown={handleMouseDown}
                onMouseMove={handleMouseMove}
                onMouseUp={handleMouseUp}
                onMouseLeave={handleMouseUp}
                onTouchStart={handleTouchStart}
                onTouchMove={handleTouchMove}
                onTouchEnd={handleTouchEnd}
            >
                <div
                    className="tempo-slider-fill"
                    style={{ height: `${fillHeight}%` }}
                />
                <div
                    className="tempo-slider-handle"
                    style={{ bottom: `${fillHeight}%` }}
                />
            </div>
            <div className="tempo-value">
                {currentTempo ? Math.round(currentTempo) : "?"} BPM
            </div>
        </div>
    );
};

export default TempoSlider;

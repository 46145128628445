import React, {Component} from "react";
import {injectIntl} from "react-intl";

import ISearchPageProperties from './ISearchPageProperties';
import './SearchPage.css'
import {IGestureMatch} from "../../IGestureMatch";
import SearchMatch from "../SearchMatch";

class SearchPage extends Component<ISearchPageProperties, any> {

    render() {
        const matchesToRender = this.props.matches.filter((match: IGestureMatch) => new Date().getTime() / 1000 - match.request_time < 60);

        return (
            <div>
                <div style={{display: "flex", flexDirection: "row"}}>
                    {matchesToRender.map((m: IGestureMatch, idx: any, arr: any) => <SearchMatch
                        key={'match_' + idx}
                        match={m}
                        fractions={1.0 / arr.length}
                        onPlayGestureMatch={this.props.onPlayGestureMatch}
                    />)}
                </div>
                {this.props.children}
            </div>
        );
    }
}

export default injectIntl(SearchPage);
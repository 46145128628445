import React, {Component} from "react";
import {injectIntl, FormattedMessage} from "react-intl";

import './MenuBar.css';
import IMenuBarProperties from './IMenuBarProperties';
import {AppPages} from "../../types/AppPages";


class MenuBar extends Component<IMenuBarProperties, any> {

    render() {

        const showSearch = false && !window.location.hostname.endsWith("train.peachnote.net") &&
            !window.location.hostname.endsWith("globalmusicbrain.com") &&
            !window.location.hostname.endsWith("d2k6cfoykqf241.cloudfront.net");

        return (
            <div className="Menu-bar">

                {showSearch &&
                <div className={"Menu-tab" + (this.props.activeTab === AppPages.SEARCH ? '-active' : '')}
                     onClick={() => this.props.onTabChange(AppPages.SEARCH)}
                >
                    <FormattedMessage
                        id="app.menu.search"
                        defaultMessage='Search'
                    />
                </div>
                }

                <div className={"Menu-tab" + (this.props.activeTab === AppPages.TRAINING ? '-active' : '')}
                     style={{
                         borderLeft: showSearch ? "0.5px solid darkgrey" : undefined,
                         borderRight: "0.5px solid darkgrey"
                     }}
                     onClick={() => this.props.onTabChange(AppPages.TRAINING)}
                >
                    <FormattedMessage
                        id="app.menu.training"
                        defaultMessage='Practice'
                    />
                </div>
                <div className={"Menu-tab" + (this.props.activeTab === AppPages.ACCOUNT ? '-active' : '')}
                     onClick={() => this.props.onTabChange(AppPages.ACCOUNT)}
                >
                    {this.props.isLoggedIn ?
                        <FormattedMessage
                            id="app.menu.account"
                            defaultMessage='Me'
                        /> :
                        <FormattedMessage
                            id="app.menu.login"
                            defaultMessage='Register'
                        />
                    }
                </div>
            </div>
        )
    }


}

export default injectIntl(MenuBar)
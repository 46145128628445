import React, { Component, ErrorInfo, ReactNode } from "react";

interface Props {
    children: ReactNode;
}

interface State {
    hasError: boolean;
    error: Error | undefined;
    errorInfo: ErrorInfo | undefined;
}

class ErrorBoundary extends Component<Props, State> {
    public state: State = {
        hasError: false,
        error: undefined,
        errorInfo: undefined
    };

    public static getDerivedStateFromError(e: Error): State {
        // Update state so the next render will show the fallback UI.
        return { hasError: true, error: e, errorInfo: undefined };
    }

    public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        console.error("Uncaught error:", error, errorInfo);
        this.setState({errorInfo: errorInfo})
    }

    public render() {
        if (this.state.hasError) {
            return <div>
                <h1>Sorry.. there was an error</h1>
                <div>{this.state.error}</div>
                <div>{this.state.errorInfo}</div>
            </div>;
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
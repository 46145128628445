import React from 'react';

const ScoreUploadDialog = ({ fileName, onSubmit, onCancel }) => {
    const [scoreName, setScoreName] = React.useState(fileName.replace(/\.[^/.]+$/, ""));

    return (
        <div style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0,0,0,0.5)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1000
        }}>
            <div style={{
                backgroundColor: 'white',
                padding: '20px',
                borderRadius: '5px',
                maxWidth: '300px'
            }}>
                <h3>Upload Score</h3>
                <p>Enter a name for the score:</p>
                <input
                    type="text"
                    value={scoreName}
                    onChange={(e) => setScoreName(e.target.value)}
                    style={{ width: '100%', marginBottom: '10px' }}
                />
                <button onClick={() => onSubmit(scoreName)}>Upload</button>
                <button onClick={onCancel} style={{ marginLeft: '10px' }}>Cancel</button>
            </div>
        </div>
    );
};

export default ScoreUploadDialog;